import React from "react"
import parse from "html-react-parser"

const SVG = ({ id, width, height, title, ...other }) => {
	return (
		<svg width={width} height={height} {...other}>
			{ !!title && (
				<title>{parse(title)}</title>
			) }

			<use xlinkHref={`#${id}`}></use>
		</svg>
	)
}
export default SVG
